import React from 'react';
import styled from 'styled-components';

import { md } from 'utils/breakpoints';

const Container = styled.div`
  color: #214a4c;
  font-size: 16px;
  font-weight: 400;
  padding: 16px 0;
  text-align: center;
  border-top: 1px solid #e3e3e3;
  margin: 20px -100% 0px;

  @media (max-width: ${md}) {
    font-size: 12px;
  }
`;

const Footer = (props) => {
  const { className } = props;

  return (
    <Container className={className}>
      Copyright 2023 Carrefour.All Rights Reserved
    </Container>
  );
};

export default Footer;
