import React, { useState, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { getters } from 'redux/store';
import _ from 'lodash';
import HeaderWrapper from 'components/UI/Header/HeaderWrapper';
import Header from 'components/UI/Header';
import Breadcrumb from 'components/UI/Breadcrumb';
import Footer from 'components/UI/Footer';
import Button from 'components/UI/Button';
import { md } from 'utils/breakpoints';
import { isAuthenticated } from 'utils/_auth';
import IconPoints from 'images/points.svg';
import IconCart from 'images/shopping-cart.svg';
import {
  PATH_ORDER,
  PATH_REFUND,
  PATH_SHOPPING_INFORMATION,
  PATH_FAQ,
  PATH_RECEIVER,
  PATH_MEMBER_EDIT,
  PATH_TERMS,
  PATH_PRIVACY_POLICY,
  PATH_RETURN_POLICY,
  PATH_MY_COUPON,
} from 'utils/constants/paths.js';

const Container = styled.div`
  width: 1080px;
  padding-top: ${(props) => `${props.headerHeight}px`};
  margin: 0 auto;

  @media (max-width: ${md}) {
    width: 100%;
  }
`;

const StyledHeader = styled(Header)`
  .switch-delivery-wrapper,
  .address-wrapper,
  .input-search-wrapper,
  .btn-cart-wrapper {
    @media (max-width: ${md}) {
      display: none;
    }
  }
`;

const StyledFooter = styled(Footer)`
  @media (max-width: ${md}) {
    display: none;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 0 16px;
`;

const Sidebar = styled.div`
  flex: 0 0 300px;

  @media (max-width: ${md}) {
    display: none;
  }
`;

const MemberInfoBox = styled.div`
  background-color: #bef0f3;
  border-radius: 8px;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px 0px;
`;

const MemberInfoTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #3b3516;
`;

const MemberInfoPoints = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #3b3516;
  display: flex;
  align-items: baseline;

  > span {
    font-size: 24px;
    font-weight: 900;
    color: #32b1c0;
    margin: 0 8px;
  }
`;

const MenuList = styled.div`
  padding: 17px 20px 24px;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 9px 0;
`;

const Menu = styled.div``;

const MenuTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #3b3516;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const MenuSubTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #3b3516;
  padding: 8px;
  border: 1px solid transparent;
  cursor: pointer;

  ${(props) =>
    props.isActive &&
    css`
      background-color: #eafeff;
      border: 1px solid #5fd2da;
      border-radius: 8px;
    `}

  ${(props) =>
    props.disabled &&
    css`
      color: #868686;
      cursor: not-allowed;
    `}
`;

const Content = styled.div`
  flex: 1;
`;

const StyledIconCart = styled.img`
  margin-right: 8px;
`;

const BtnGroup = styled.div`
  display: flex;
  gap: 0px 8px;
  width: 236px;
  margin: 0 auto;
`;

const StyledBtn = styled(Button)`
  flex: 1;
  height: 38px;
  background: #ffffff;
  color: #3b3516;

  ${(props) =>
    props.primary &&
    css`
      background-color: #eafeff;
      border: 1px solid #5fd2da;
    `}
`;

const CouponCountLabel = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CouponCount = styled.span`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #5fd2da;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
`;

const isGuest = isAuthenticated() ? false : true;

const Layout = (props) => {
  const { children, className, extraBtn, location, pageTitle } = props;

  const userInfo = useSelector((state) => getters.getUserInfo(state));
  const myCouponList = useSelector((state) => getters.getMyCouponList(state));

  const [headerHeight, setHeaderHeight] = useState(0);
  const [name, setName] = useState('');
  const [point, setPoint] = useState(0);

  const menuList = [
    [
      '購物相關',
      [
        { label: '訂單查詢', path: PATH_ORDER, isGuestDisabled: true },
        {
          label: (
            <CouponCountLabel>
              我的折價券
              <CouponCount>{myCouponList?.length || 0}</CouponCount>
            </CouponCountLabel>
          ),
          path: PATH_MY_COUPON,
          isGuestDisabled: true,
        },
        { label: '退訂退款查詢', path: PATH_REFUND, isGuestDisabled: true },
        { label: '購物須知', path: PATH_SHOPPING_INFORMATION },
        {
          label: '常見問題',
          path: PATH_FAQ,
          openInNewTab: true,
        },
      ],
    ],
    [
      '個人帳戶管理',
      [
        { label: '收件人管理', path: PATH_RECEIVER, isGuestDisabled: true },
        {
          label: '會員資料維護',
          path: PATH_MEMBER_EDIT,
          isGuestDisabled: true,
        },
        /* { label: '申請刪除帳號', path: '/member/delete', isGuestDisabled: true }, */
      ],
    ],
    [
      '相關條款',
      [
        { label: '會員服務條款', path: PATH_TERMS },
        { label: '隱私權政策', path: PATH_PRIVACY_POLICY },
        { label: '退貨須知', path: PATH_RETURN_POLICY },
      ],
    ],
  ];

  const handleClickSubTitle = (parentIdx, childIdx) => {
    const option = menuList[parentIdx][1][childIdx];
    if (option.openInNewTab) {
      window.open(option.path, '_blank');
      return;
    }
    navigate(option.path);
  };

  useEffect(() => {
    setName(userInfo?.name ?? '');
    let num = userInfo?.hkPoint ?? 0;
    let formattedNum = num.toLocaleString();
    setPoint(formattedNum);
  }, [userInfo]);

  return (
    <Container className={className} headerHeight={headerHeight}>
      <HeaderWrapper getHeight={(height) => setHeaderHeight(height)}>
        <StyledHeader title={pageTitle} extraBtn={extraBtn} />
      </HeaderWrapper>
      <Breadcrumb
        items={[
          { title: <Link to="/">首頁</Link> },
          { title: <Link to="/">會員中心</Link> },
        ]}
      />
      <ContentWrapper>
        <Sidebar>
          <MemberInfoBox>
            {isGuest ? (
              <>
                <MemberInfoTitle>訪客 你好</MemberInfoTitle>
                <BtnGroup>
                  <StyledBtn primary>註冊</StyledBtn>
                  <StyledBtn>登入</StyledBtn>
                </BtnGroup>
              </>
            ) : (
              <>
                <MemberInfoTitle>{name}</MemberInfoTitle>
                <MemberInfoPoints>
                  <img src={IconPoints} />
                  <span>{point}</span> 點
                </MemberInfoPoints>
              </>
            )}
          </MemberInfoBox>
          <MenuList>
            {menuList.map((menu, parentIdx) => {
              return (
                <Menu>
                  <MenuTitle>
                    <StyledIconCart src={IconCart} />
                    {menu[0]}
                  </MenuTitle>
                  {menu[1].map((menuChild, childIdx) => {
                    const isDisabled = isGuest && menuChild.isGuestDisabled;
                    const menuPath = menuChild.path;
                    const regex = new RegExp(`^${menuPath}(?:/[\w\d\s\S]*)?`);
                    const isActive = regex.test(location.pathname);
                    return (
                      <MenuSubTitle
                        isActive={isActive}
                        disabled={isDisabled}
                        onClick={() => {
                          if (isDisabled) return;
                          handleClickSubTitle(parentIdx, childIdx);
                        }}
                      >
                        {menuChild.label}
                      </MenuSubTitle>
                    );
                  })}
                </Menu>
              );
            })}
          </MenuList>
        </Sidebar>
        <Content>{children}</Content>
      </ContentWrapper>
      <StyledFooter />
    </Container>
  );
};

export default Layout;
