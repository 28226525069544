import React from 'react';
import styled from 'styled-components';

import Layout from 'components/Order/Layout';
import TitleName from 'components/UI/Header/TitleName';

import { md } from 'utils/breakpoints';

const Container = styled.div``;

const TitleBar = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #3b3516;
  margin-bottom: 16px;

  @media (max-width: ${md}) {
    display: none;
  }
`;

const Card = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  color: #000000;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px 0;
`;

const BlockTitle = styled.div`
  font-weight: 700;
`;

const UlBlock = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  display: flex;
  position: relative;
  padding-left: 20px;

  &::before {
    content: '．';
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const ReturnPolicy = (props) => {
  const pageTitle = '退貨須知';

  return (
    <Container>
      <TitleName />
      <Layout {...props} pageTitle={pageTitle}>
        <Container>
          <TitleBar>{pageTitle}</TitleBar>
          <Card>
            <BlockTitle>收到的商品品質不如預期,我可以怎麼做?</BlockTitle>
            <div>一般商品均享有七天猶豫期,但部分特殊商品有不同規範,如下</div>
            <div>
              <UlBlock>
                生鮮商品:生鮮商品因易於腐敗(如現做餐盒或蔬果等生鮮食品)、保存期限較短或解約時即將逾期(如蛋糕或鮮奶),依現行消費者保護法之規定,無法享有退換貨之服務。
              </UlBlock>
              <UlBlock>
                個人衛生商品:(如已拆封之日用品、內衣褲、個人衛生用品)由於商品屬性特殊,有個人衛生之考量,依法無法享有前開之七天猶豫期,但全新商品倘有瑕疵,仍享有本公司瑕疵無條件退/換貨的售後服務。
              </UlBlock>
              <UlBlock>
                影音軟體:(如影音商品、遊戲點數、電腦軟體等)由於商品屬性特殊,有智慧財產權保障之考量,依法無法享有前開之七天猶豫期,但全新商品倘有瑕疵,仍享有本公司瑕疵無條件退/換貨的售後服務。
              </UlBlock>
            </div>
            <BlockTitle>信用卡退款作業時間約需多久呢?</BlockTitle>
            <div>
              信用卡申請退款作業流程約需7-14個工作天(不含例假日),如您需查詢退訂退款資訊,請至家速配APP版畫面右下方【會員】功能內的【退訂退款】查詢,退訂退款實際退款日將依各發卡銀行作業為主。
            </div>
            <div>
              <UlBlock>
                如您的訂單有使用折價券,部分退貨將依退貨金額按比例分攤,詳情請參閱【退貨相關】→【折扣商品的退貨問題】,如訂單是整筆取消,折價券將會在24小時內歸還,但若是您是在收到訂單商品後,自行至各門市退訂商品,將無法退還折價券。
              </UlBlock>
              <UlBlock>
                如您選購的商品為買一送一或第二件N折,商品退貨時,請以原購買數量為退貨單位,退貨金額為原購買金額。
              </UlBlock>
              <UlBlock>
                如您選購商品有贈品時,退貨時贈品亦需同時退回。若原消費付款方式為信用卡一次付清,須信用卡持卡本人至各家樂福門市服務台辦理信用卡刷退手續,若原訂單發票有輸入統一編號,請您記得攜帶票、公司大小章或發票章。
              </UlBlock>
              <UlBlock>
                每筆訂單將酌收35元物流處理費,無論是商品送達後拒收、取消,欲辦理退貨,因商品已出,運費皆無法返還。
              </UlBlock>
            </div>
          </Card>
        </Container>
      </Layout>
    </Container>
  );
};

export default ReturnPolicy;
