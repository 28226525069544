import React from 'react';
import styled from 'styled-components';

import { md } from 'utils/breakpoints';

const Container = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #868686;
  display: flex;
  margin-bottom: 8px;

  @media (max-width: ${md}) {
    display: none;
  }
`;

const BreadcrumbItem = styled.div`
  padding: 0 10px;
  position: relative;

  &::after {
    content: '/';
    position: absolute;
    right: -5px;
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;

    &::after {
      content: '';
    }
  }
`;

const Breadcrumb = (props) => {
  const { className, items } = props;

  return (
    <Container className={className}>
      {items.map((item, idx) => {
        return <BreadcrumbItem key={idx}>{item.title}</BreadcrumbItem>;
      })}
    </Container>
  );
};

export default Breadcrumb;
